export default {
    mainCatalogSlider: {
        loop: false,
        slidesPerView: 'auto',
        watchOverflow: true
    },
    mainAdvantagesSlider: {
        loop: false,
        effect: 'fade',
        speed: 0,
        lazy: true,
        preloadImages: false,
        autoHeight: true,
        navigation: 'default',
        pagination: 'fraction',
        watchOverflow: true
    },
    simpleSlider: {
        loop: false,
        effect: 'fade',
        speed: 0,
        lazy: true,
        preloadImages: false,
        navigation: 'default',
        pagination: 'fraction',
        watchOverflow: true
    },
    clientsSlider: {
        loop: false,
        lazy: {
            loadPrevNext: true,
            loadPrevNextAmount: 4
        },
        preloadImages: false,
        slidesPerView: 'auto',
        watchOverflow: true
    },
    partnersSlider: {
        loop: false,
        navigation: 'default',
        pagination: 'fraction',
        slidesPerView: 2,
        slidesPerColumn: 4,
        slidesPerColumnFill: 'row',
        watchOverflow: true,
        breakpoints: {
            768: {
                slidesPerView: 3
            },
            992: {
                slidesPerView: 4
            }
        }
    },
    tabsNavSlider: {
        loop: false,
        freeMode: true,
        watchOverflow: true,
        slidesPerView: 'auto'
    }
};