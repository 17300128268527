import TaskRunner from '../base/TaskRunner';
import * as Watcher from '../components/viewportWatcher';

TaskRunner.add(() => {
    if (document.querySelector('.page_main')) {
        const blocksChangeTime = 1000; // Время смены слайдов
        const scrollerMinHeight = 800; // Минимальная высота окна при которой включается постраничная прокрутка
        let scrollLocked = true; // Флаг, который отвечает за блокировку скролла (отключение главного слайдера)

        const pageMainLayoutSlider = document.getElementById('pageMainLayoutSlider');

        const unlockScroll = () => {
            scrollLocked = false;
        };

        const startStopAutoplay = (block, start = false) => {
            let innerElem = block.querySelector('[data-autoplay] .swiper-container');

            if (innerElem && innerElem.swiper.autoplay) {
                start ? innerElem.swiper.autoplay.start() : innerElem.swiper.autoplay.stop();
            }

            innerElem = block.querySelector('video');

            if (innerElem) {
                start ? innerElem.play() : innerElem.pause();
            }
        };

        const slidesChangeHandler = (swiper) => {
            // const currentSlideEl = swiper.slides[swiper.activeIndex];
            // const previousSlideEl = swiper.slides[swiper.previousIndex];

            scrollLocked = true;
            setTimeout(unlockScroll, blocksChangeTime);

            pageMainLayoutSlider.classList.toggle('page_main--layout-end', swiper.activeIndex + 1 === swiper.slides.length);

            // try {
            //     startStopAutoplay(currentSlideEl, true);
            //     startStopAutoplay(previousSlideEl);
            //     // var innerSwiperElem = currentSlideEl.querySelector('[data-autoplay] .swiper-container');

            //     // if (innerSwiperElem && innerSwiperElem.swiper.autoplay) {
            //     //     innerSwiperElem.swiper.autoplay.start();
            //     // }

            //     // innerSwiperElem = previousSlideEl.querySelector('[data-autoplay] .swiper-container');

            //     // if (innerSwiperElem && innerSwiperElem.swiper.autoplay) {
            //     //     innerSwiperElem.swiper.autoplay.stop();
            //     // }
            // } catch (err) {
            //     console.warn('Unable to find inner swiper! ' + err.message)
            // }
        };

        let pageLayoutSwiper;
        const settings = {
            loop: false,
            // pagination: {
            //     el: '#pageMainLayoutSliderPagination',
            //     clickable: true
            // },
            direction: 'vertical',
            slidesPerView: 1,
            speed: blocksChangeTime,
            preventInteractionOnTransition: true,
            // shortSwipes: false,
            // longSwipesRatio: .2,
            // longSwipesMs: 200,
            followFinger: false,
            keyboard: {
                enabled: true,
            },
            // mousewheel: {
            //     thresholdTime: 400,
            //     forceToAxis: true
            // },
            on: {
                slideChange: slidesChangeHandler
            },
            preventClicks: false,
            preventClicksPropagation: false,
            allowTouchMove: false,
            shortSwipes: false,
            lognSwipes: false,
            edgeSwipeDetection: true
        };

        const isSliderEnabled = () => {
            // Если ширина окна меньше 1200 или высота меньеш 670 то слайдер не заработает
            return window.innerWidth >= 1200 && window.innerHeight >= scrollerMinHeight;
        };

        const initMainSlider = () => {
            if (isSliderEnabled() && !pageLayoutSwiper) {
                scrollLocked = false;
                pageLayoutSwiper = new Swiper(pageMainLayoutSlider, settings);
            } else if (!isSliderEnabled() && pageLayoutSwiper) {
                scrollLocked = true;
                pageLayoutSwiper.destroy();
                pageLayoutSwiper = null;
            }
        };

        window.addEventListener('resize', () => {
            initMainSlider();
        });

        initMainSlider();

        

        pageMainLayoutSlider.addEventListener('wheel', (e) => {
            if (!scrollLocked) {
                e.preventDefault();

                if (e.deltaY > 0) {
                    pageLayoutSwiper.slideNext();
                } else {
                    pageLayoutSwiper.slidePrev();
                }
            }
        });

        const scrollLockerStopPropagation = (e) => {
            e.stopPropagation();
        };

        document.querySelectorAll('.page_main--layout-lock_scroll').forEach(scrollLockerEl => {
            scrollLockerEl.addEventListener('wheel', scrollLockerStopPropagation);
        });

        const viewportInHandler = (e) => {
            startStopAutoplay(e.target, true);
        };

        const viewportOutHandler = (e) => {
            startStopAutoplay(e.target);
        };

        document.querySelectorAll('.page_main--layout-slide').forEach(targ => {
            targ.addEventListener(Watcher.IN_VIEWPORT, viewportInHandler);
            targ.addEventListener(Watcher.OUT_VIEWPORT, viewportOutHandler);
        });
    }
});