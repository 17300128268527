/**
 * Модуль попапов
 * Доступные методы: .show(), .hide()
 */

import { bodyDisableScroll, bodyEnableScroll, wh } from '../base/util';
import LiveEvent from '../base/LiveEvent';

const POSITION_INNER = 'inner';
const POSITION_OUTER = 'outer';

const IFRAME_SELECTOR = 'iframe[src*="youtube"]';

const defaults = {
    closePos: POSITION_INNER,         // Позиция кнопки закрытия. Может быть внутри или снаружи самого окна
    scrollBackground: false,           // Флаг прокрутки контента за модалкой
};

const closeToEscape = (e) => {
    if (e.key === "Escape") {
        let currPopup = document.querySelector('.popup-root.popup-initialized.popup-visible');

        if (currPopup && currPopup.popup) currPopup.popup.hide();

        e.preventDefault();
    }
};

const getPopupTemplate = (closePos, contentElem) => {
    let templ = document.createElement('div');
    templ.classList.add('popup-root', 'popup-initialized');
    let container = document.createElement('div');
    container.classList.add('popup-container');
    let overlay = document.createElement('div');
    overlay.classList.add('popup-overlay', 'popup-close');

    let closeBtn = document.createElement('button');
    closeBtn.classList.add('popup-cross', 'popup-close');
    closeBtn.setAttribute('type', 'button');

    templ.appendChild(container);
    container.appendChild(overlay);

    switch (closePos) {
        case POSITION_INNER:
            contentElem.appendChild(closeBtn);
            break;
        case POSITION_OUTER:
            container.appendChild(closeBtn);
            break;
        default:
            console.warn('Wrong closePos value!');
            return null;
    }

    let id = contentElem.id;
    contentElem.removeAttribute('id');
    templ.setAttribute('id', id);

    container.appendChild(contentElem);

    return templ;
};

export default class Popup {
    constructor(contentElement, options = defaults) {
        this.opts = Object.assign({}, defaults, options);
        this.content = contentElement;

        this.root = getPopupTemplate(this.opts.closePos, this.content);
        this.root.popup = this;

        // this.container = this.root.children[0];

        document.body.appendChild(this.root);
    }

    show() {
        this.root.classList.add('popup-visible');

        let cancelScroll =
            !this.opts.scrollBackground ||
            wh() < this.root.scrollHeight;

        // Если высота окна меньше чем высота модалки то запрещаем скроллить html (модалка скроллится)
        if (cancelScroll) {
            bodyDisableScroll();

            if (wh() >= this.root.scrollHeight) {
                this.root.classList.add('popup-non-scrollable');
            }
        }

        // Поставим слушатель эскейпа
        document.addEventListener('keydown', closeToEscape);
    }

    hide() {
        var iframe = this.content.querySelector(IFRAME_SELECTOR);

        if (iframe) {
            var src = iframe.src;
            iframe.src = '';
            iframe.src = src;
        }

        var video = this.content.querySelector('video');

        if (video) {
            video.pause();
        }

        // Скрываем модалку
        this.root.classList.remove('popup-visible', 'popup-non-scrollable');

        // Возвращаем возможность скроллинга html
        bodyEnableScroll();

        document.removeEventListener('keydown', closeToEscape);
    }

    get options() {
        return this.opts;
    }

    get POSITION_INNER() {
        return POSITION_INNER;
    }

    get POSITION_OUTER() {
        return POSITION_OUTER;
    }

    get rootElem() {
        return this.root;
    }

    static initAll(options = defaults) {
        LiveEvent.on('click', '[data-popup]', (e, targ) => {
            let popupSelector = targ.getAttribute('data-popup');
            let popupElem = document.querySelector(popupSelector);

            if (popupElem) {
                let popup;

                if (!popupElem.classList.contains('popup-initialized')) {
                    popup = new Popup(popupElem, options);
                } else if (popupElem.popup) {
                    popup = popupElem.popup;
                } else {
                    console.warn('Popup element has not popup attribute!');
                    return;
                }

                try {
                    document.getElementById(targ.getAttribute('data-change-text-on')).innerText = targ.getAttribute('data-change-text-to');
                } catch (err) {
                    console.warn('Unable to change text in element!');
                }

                popup.show();
                e.preventDefault();
            }
        });

        LiveEvent.on('click', '.popup-close', e => {
            let popup = e.target.closest('.popup-root');

            if (popup && popup.popup) {
                popup.popup.hide();
                e.preventDefault();
            }
        });
    }

    static getObject(selector) {
        let elem = document.querySelector(selector);

        if (elem) {
            return typeof elem.popup !== 'undefined' ? elem.popup : new Popup(elem);
        }

        return null;
    }
}